



























































































































































































































































import Vue from 'vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import IVertragsfaten from '@/model/IVertragsfaten';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

export default Vue.extend({
  name: 'elektronik-insurance-risk',
  mixins: [dataChangeMixin],
  components: {
    popUpModal,
  },
  data() {
    return {
      name: 'elektronik-insurance-risk',
      key: 'ELEKTRONIK_INSURANCE',
      backUrl: '',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      paramsVertragsdaten: {
        sumInsured: '',
        sum371: '',
        paymentMethod: '',
        versicherungssteuer: '',
        netization: '',
        factorLevel: '',
      },
      schadenverlaufObj: {
        value: 2,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      betriebsstundenObj: {
        value: 2,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      maintenanceQualitatObj: {
        value: 2,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      mengenrabattObj: {
        value: 2,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      sonstigesBesichtigungObj: {
        value: 2,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      vertragsbezogeneRabatte: [
        {
          value: 0,
          text: 'Entspricht nicht den marktüblichen Standards.',
        },
        {
          value: 1,
          text: 'Entspricht teilweise den marktüblichen Standards',
        },
        {
          value: 2,
          text: 'Entspricht den marktüblichen Standards',
        },
        {
          value: 3,
          text: 'Übertrifft teilweise die marktüblichen Standards',
        },
        {
          value: 4,
          text: 'Übertrifft die marktüblichen Standards',
        },
      ],
      ergebnisDerBewertung: {
        GesamtergebnisGemabRisikofaktorenbewertung: '',
      },
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData'],
  watch: {
    'schadenverlaufObj.value': function (val: any) {
      this.schadenverlaufObj.text = this.vertragsbezogeneRabatte[val].text;
    },
    'betriebsstundenObj.value': function (val: any) {
      this.betriebsstundenObj.text = this.vertragsbezogeneRabatte[val].text;
    },
    'maintenanceQualitatObj.value': function (val: any) {
      this.maintenanceQualitatObj.text = this.vertragsbezogeneRabatte[val].text;
    },
    'mengenrabattObj.value': function (val: any) {
      this.mengenrabattObj.text = this.vertragsbezogeneRabatte[val].text;
    },
    'sonstigesBesichtigungObj.value': function (val: any) {
      this.sonstigesBesichtigungObj.text = this.vertragsbezogeneRabatte[val].text;
    },
  },
  methods: {
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl);
    },
    paramsVertragsdatenChange(params: IVertragsfaten) {
      this.paramsVertragsdaten.sumInsured = params.sumInsured;
      this.paramsVertragsdaten.sum371 = params.sum371;
      this.paramsVertragsdaten.paymentMethod = params.paymentMethod;
      this.paramsVertragsdaten.versicherungssteuer = params.versicherungssteuer;
      this.paramsVertragsdaten.netization = params.netization;
      this.paramsVertragsdaten.factorLevel = params.factorLevel;
    },
    onSubmit() {
      const data = {
        paramsVertragsdaten: this.paramsVertragsdaten,
        schadenverlaufObj: this.schadenverlaufObj,
        betriebsstundenObj: this.betriebsstundenObj,
        maintenanceQualitatObj: this.maintenanceQualitatObj,
        mengenrabattObj: this.mengenrabattObj,
        sonstigesBesichtigungObj: this.sonstigesBesichtigungObj,
        ergebnisDerBewertung: this.ergebnisDerBewertung,
      };
      this.$emit('risk-factor-data-change', data);
    },
    setValues(insuranceData: any) {
      const { riskFactorData } = insuranceData;
      if (riskFactorData) {
        this.paramsVertragsdaten = riskFactorData.paramsVertragsdaten;
        this.schadenverlaufObj = riskFactorData.schadenverlaufObj;
        this.betriebsstundenObj = riskFactorData.betriebsstundenObj;
        this.maintenanceQualitatObj = riskFactorData.maintenanceQualitatObj;
        this.mengenrabattObj = riskFactorData.mengenrabattObj;
        this.sonstigesBesichtigungObj = riskFactorData.sonstigesBesichtigungObj;
        this.ergebnisDerBewertung = riskFactorData.ergebnisDerBewertung;
      }
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    (this as any).startWatcherActivated();
  },
});
